<template>
  <div class="Levelsetpar">
    <div class="top-titles">
      <div class="top-text">等级条件修改</div>
      
    </div>

  <div class="Brokerage-box">
      <el-form ref="form" label-width="100px">
        <div class="Brokerage-li">
         <el-form-item label="等级名称:" >
                  <el-input v-model="listpar.gradeName"></el-input>
          </el-form-item>
        </div>
        <div class="Brokerage-li">
         <el-form-item label="升级条件:" >
                  <el-input v-model="listpar.upgrade"></el-input>
          </el-form-item>
        </div>

        <div class="Brokerage-li">
              <el-button
                type="primary"
                class="biy-icon" icon="el-icon-check" @click="tijiao()"
                >提交</el-button>
        </div> 
        </el-form>
</div>

  </div>
</template>
<script>
import fakelist from "@/api/fakelist.js"
import apiurl from "@/api/apiurl/apiurl.js"
import config from "@/request/config.js"
export default {
  data() {
    return {
            // 上传图片的地址
      upLoadUrl:config.ImgUrl,
      // 下拉框
      seeks: {},
      listpar:{},
      list:[],
    };
  },
  created() {
            this.getSong();
  },
  mounted() {},

  methods: {

    //查询接口
    async getSong() {
      let res = await apiurl.deptlist({
        // "FOREND_TOKEN":baseGetToken(),
      });
      if (typeof res.ErrMsg == "undefined") {
        alert("接口调用失败");
      } else if (res.ErrMsg) {
        alert("接口调用失败:" + res.ErrMsg);
      } else {
        this.list = fakelist.Levelset.rows;
        for (let i = 0; i < this.list.length; i++) {
          const lists = this.list[i];
          if (lists.id == this.$route.params.id) {
            this.listpar = lists;
          }
        }
      }
    },
    // 提交
    tijiao(){
       
    },

  },
  computed: {},
};
</script>


<style  scoped>
/* 搜索栏样式 */
.top-titles{
    width: 94%;
    margin: 0 auto;
}
.top-text{
    height: 30px;
    font-size: 18px;
}
.Brokerage-box{
  width: 600px;
  margin: 100px auto;
}
.Brokerage-li:nth-of-type(1){
  width: 50%;
}
.Brokerage-li{
  width: 100%;
  height: 80px;
}
/* 搜索按钮 */
.biy-icon {
    width: 100px;
    text-align: center;
    height: 36px;
    line-height: 36px;
    padding: 0;
    margin-left: 80px;
    background-color: #01847F;
  }
</style>